
import { useContext, useEffect, useMemo, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { Navigate, RouterProvider, createBrowserRouter, useLocation, useNavigate } from 'react-router-dom';
import LanguageWrapper from './LanguageWrapper';
import Component from './constants/Component';
import CallApi from './context/CallApi';
import CarFiltersApis from './context/CarFiltersApis';
import FiltersCars from './context/FiltersCars';
import LangChange, { LocalizationContext } from './context/LangChange';
import './style/App.scss';
import axios from 'axios';
import img from './constants/Img';

const EnsureLanguagePrefix = ({ children }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { isLang } = useContext(LocalizationContext);

  useEffect(() => {
    // تحقق مما إذا كان المسار يبدأ بأي من اللغات المدعومة
    const pathSegments = pathname.split('/').filter(Boolean);
    const firstSegment = pathSegments[0];

    if (firstSegment !== 'en' && firstSegment !== 'ar') {
      // إعادة توجيه إلى المسار الصحيح مع بادئة اللغة
      navigate(`/${isLang}${pathname}`, { replace: true });
    }
  }, [pathname, navigate, isLang]);

  return children;
};

function App() {
  const { isLang } = useContext(LocalizationContext);


  function ProtectedRoutes({ children }) {
    let token = localStorage.getItem('client_id')
    if (!token) {
      localStorage.setItem('openLogin', 'open')
      return <Navigate to="/auth/sign-in" replace={true} />
    } else {
      localStorage.setItem('openLogin', 'close')
      return children;
    }
  }

  const routes = useMemo(() => createBrowserRouter([
    // إعادة التوجيه للغة الافتراضية إذا لم يتم تحديد اللغة
    {
      path: '/',
      element: <Navigate to={`/${isLang == 'en' ? 'en' : 'ar'}`} replace={true} />
    },
    {
      path: '/:lang',
      element: <EnsureLanguagePrefix><LanguageWrapper /></EnsureLanguagePrefix>, // غلاف اللغة والتأكيد على البادئة
      children: [
        {
          path: '',
          element: <Component.Alghazal />,
          children: [
            { index: true, element: <Component.Home /> },
            { path: 'about', element: <Component.AboutUS /> },
            { path: 'career', element: <Component.Career /> },
            { path: 'contactus', element: <Component.Connect /> },
            { path: 'branches', element: <Component.Branches /> },
            { path: 'thanks-page/:type', element: <Component.Thanks /> },
            { path: 'thanks-page/order/:id', element: <Component.Thanks /> },
            { path: 'payment', element: <Component.BankView /> },
            { path: 'privacy-policy', element: <Component.Policy /> },
            { path: 'terms-of-use', element: <Component.Terms /> },
            { path: 'terms-cancellation', element: <Component.TermsCancellation /> },
            {
              path: 'offers', children: [
                { index: true, element: <Component.Offers /> },
                { path: 'add', element: <ProtectedRoutes> <Component.DetailsOffers /> </ProtectedRoutes> },
                { path: 'details/:id', element: <Component.DetailsOffers /> },
              ]
            },
            { path: 'od/:id', element: <Component.DetailsOffers /> },
            {
              path: 'cars', children: [
                { index: true, element: <Component.ShownCars /> },
                { path: 'limousine', element: <Component.LimousineCars /> },
                { path: 'limousine/details/:id', element: <Component.LimousineDetails /> },
                { path: ':id', element: <Component.PaymentDetails /> },
                {
                  path: 'car_details', children: [
                    { path: ':id', element: <ProtectedRoutes><Component.CarPayDetails /> </ProtectedRoutes> },
                  ]
                }
              ]
            },
            { path: 'cd/:id', element: <Component.PaymentDetails /> },
            { path: '*', element: <Component.NotFound /> },
          ]
        },
        {
          path: 'auth', element: <Component.Auth />, children: [
            { path: 'sign-in', element: <Component.Login /> },
            { path: 'sign-in/:code', element: <Component.Login /> },
            { path: 'sign-up', element: <Component.Register /> },
            { path: 'forget-password', element: <Component.ForgetPassword /> },
            { path: 'check-account', element: <Component.ForgetPassword /> },
            { path: 'activation', element: <Component.ActivationAccount /> },
          ]
        },
        {
          path: 'account', element: <Component.Account />, children: [
            {
              path: 'personal_data', children: [
                { index: true, element: <ProtectedRoutes><Component.PersonalData /> </ProtectedRoutes> },
                { path: 'edit', element: <ProtectedRoutes> <Component.EditAccount /> </ProtectedRoutes> },
              ]
            },
            { path: 'points', element: <ProtectedRoutes>  <Component.Points /></ProtectedRoutes> },
            { path: 'delivery_address', element: <ProtectedRoutes>  <Component.DeliveryAddress /> </ProtectedRoutes> },
            {
              path: '', children: [
                { path: 'bookings', element: <ProtectedRoutes>  <Component.Bookings /> </ProtectedRoutes> },
                { path: 'manage', element: <ProtectedRoutes>  <Component.ManageReservations /></ProtectedRoutes> },
              ]
            },
          ]
        },
      ]
    }
  ]), []);

  const [isSiteActive, setIsSiteActive] = useState(true); // للتحقق من حالة الموقع

  useEffect(() => {
    // استدعاء API للتحقق من حالة الموقع
    const checkSiteStatus = async () => {
      try {
        const {data} = await axios.get('https://alghazal.sa/gazalservices/checksystem');
        console.log(data);
        if (data.status === true) {
          setIsSiteActive(true); // الموقع يعمل
        } else {
          setIsSiteActive(false); // الموقع في حالة صيانة
        }
      } catch (error) {
        console.error("Error fetching site status:", error);
        setIsSiteActive(false); // في حالة وجود خطأ، افترض أن الموقع في حالة صيانة
      }
    };

    checkSiteStatus();
  }, []);
  if (!isSiteActive) {
    return (
      <div style={{ textAlign: 'center', marginTop: '50px' }}>
        <img src={img.Logo} alt="" srcset="" />
        <h1>الموقع تحت الصيانة</h1>
        <p>نعتذر عن الإزعاج، يرجى المحاولة مرة أخرى لاحقًا.</p>
      </div>
    );
  }

  return (
    <>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          duration: 3500,
          style: {
            fontFamily: ' Arial, Helvetica, sans-serif',
            textTransform: 'capitalize',
            zIndex: '9999',
            // background: '#000',
            // color: '#fff',
            borderRadius: '10px',
            boxShadow: '10px 10px 10px rgba(188, 188, 188, 0.16)',
            background: '#fff',
            color: '#000',
          },
        }}
        containerStyle={{
          bottom: 50
        }}
      />
      <CallApi>
        <CarFiltersApis>
          <LangChange>
            <FiltersCars>
              <RouterProvider router={routes} />
            </FiltersCars>
          </LangChange>
        </CarFiltersApis>
      </CallApi>
    </>
  );
}

export default App;
